import { App } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser, OpenOptions } from '@capacitor/browser';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { NativeBiometric, type AvailableResult, BiometryType, type BiometricOptions } from '@capgo/capacitor-native-biometric';
/* disable it because it is not used; the package has been updated and testing is required
import { CapacitorUpdater } from '@capgo/capacitor-updater';
*/

import { themingInstance } from '@exchange/libs/utils/theming/src';

export const capGetPlatform = Capacitor.getPlatform.bind(Capacitor);
export const capIsNativePlatform = Capacitor.isNativePlatform.bind(Capacitor);
export const capApp = App;
export const capAppLauncher = AppLauncher;
export const capBrowser = {
  ...Browser,
  addListener: Browser.addListener.bind(Browser),
  removeAllListeners: Browser.removeAllListeners.bind(Browser),
  open: (options: OpenOptions) =>
    Browser.open({
      ...options,
      toolbarColor: themingInstance.getColor('--v-theme-elevation-0'),
    }),
};
export const capClipboard = Clipboard;
export const capDirectory = Directory;
export const capFilesystem = Filesystem;
export const capNativeBiometric = NativeBiometric;
export type CapNativeBiometricAvailableResult = AvailableResult;
export const capNativeBiometricBiometryType = BiometryType;
export type CapNativeBiometricBiometricOptions = BiometricOptions;
export const capHttp = CapacitorHttp;
export const capShare = Share;
export const capSplashScreen = SplashScreen;
export const capStatusBar = StatusBar;
/*
export const capUpdater = CapacitorUpdater;
*/
