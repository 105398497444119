import { isTooSmall } from '@exchange/helpers/balance';
import BigNumber from '@exchange/helpers/bignumber';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

export enum CurrencyType {
  CRYPTO = 'cryptocoin',
  FIAT = 'fiat',
}
export type IncomingCurrency = {
  code: string;
  name: string;
  unified_cryptoasset_id: number;
  precision: number;
};

type CurrencyModelData = {
  id: number;
  symbol: string;
  name: string;
  precision: number;
  defaultCryptoValue: number;
  defaultFiatValue: number;
};

export type CurrencyPrice = {
  symbol: string;
  eur: string;
  gbp: string;
  usd: string;
  chf: string;
  btc: string;
  eth: string;
  try: string;
};

export class CurrencyModel {
  public get transactionPrecision() {
    return this.precision;
  }

  public id: string;

  public symbol: string;

  public precision: number;

  public defaultCryptoValue: number;

  public defaultFiatValue: number;

  public name: string | undefined;

  public getCurrencyName = () => this.name;

  public get isFiat() {
    return CurrencyModel.isFiatCurrency(this.symbol);
  }

  public static isTooSmall = (value: BigNumber, precision: number) => isTooSmall(value, precision);

  public static isFiatCurrency = (currencySymbol: string) => CONSTANTS.FIAT_CURRENCIES.includes(currencySymbol);

  public static doesCryptoSupportsDestinationTag = (currencySymbol: string) => {
    const supportsDestinationTag = ['ATOM', 'EOS', 'XEM', 'XLM', 'XRP'];

    return supportsDestinationTag.includes(currencySymbol);
  };

  public static getCryptoDestinationTagType = (currencySymbol: string) => {
    if (!CurrencyModel.doesCryptoSupportsDestinationTag(currencySymbol)) {
      return undefined;
    }

    const isMemoTagType = (c: string) => ['ATOM', 'EOS', 'XLM'].includes(c);
    const isMessageType = (c: string) => ['XEM'].includes(c);
    const isDestinationTagType = (c: string) => ['XRP'].includes(c);

    if (isMemoTagType(currencySymbol)) {
      return 'memo';
    }

    if (isMessageType(currencySymbol)) {
      return 'message';
    }

    if (isDestinationTagType(currencySymbol)) {
      return 'destinationTag';
    }

    return undefined;
  };

  public checkAmountIsTooSmall = (amount: BigNumber | number | string) => CurrencyModel.isTooSmall(new BigNumber(amount), this.precision);

  constructor(data: CurrencyModelData) {
    this.id = data.symbol;
    this.symbol = data.symbol;
    this.name = data.name;
    this.precision = data.precision;
    this.defaultCryptoValue = data.defaultCryptoValue;
    this.defaultFiatValue = data.defaultFiatValue;
  }
}
