import { isEmpty } from 'lodash/fp';
import { isMobile } from 'mobile-device-detect';
import { nanoid } from 'nanoid';

import { syncWithStorage } from '@exchange/helpers/persistance-helper';
import { secureStorageGet, secureStorageSet } from '@exchange/helpers/secure-storage-helpers';
import { capGetPlatform, capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';

const PRO_DEVICE_ID = 'PRO_DEVICE_ID';

const getPersistentDeviceId = (deviceIdDefault: string) => {
  if (!capIsNativePlatform()) {
    return syncWithStorage(PRO_DEVICE_ID, deviceIdDefault);
  }

  return secureStorageGet<string>(PRO_DEVICE_ID).then((v) => {
    if (isEmpty(v)) {
      secureStorageSet(PRO_DEVICE_ID, deviceIdDefault);
      return deviceIdDefault;
    }

    return v;
  });
};

class Identifiers {
  public sessionId = nanoid();

  public sessionHeader = 'bp-ui-session-id';

  public clientHeader = 'bp-client';

  getPersistentDeviceId = () => {
    const deviceIdDefault = nanoid();

    return getPersistentDeviceId(deviceIdDefault);
  };

  getDevice = () => {
    if (capIsNativePlatform()) {
      return `app-${capGetPlatform()}`; // app-web, app-ios, app-android
    }

    return `web-${isMobile ? 'mobile' : 'desktop'}`; // web-desktop, web-mobile
  };
}

export default new Identifiers();
