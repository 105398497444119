import { isIOS, isMobileOnly } from 'mobile-device-detect';

import { capIsNativePlatform, capStatusBar } from './capacitor';

const toggleStatusBar = (value: boolean) => {
  if (capIsNativePlatform() && isMobileOnly && isIOS) {
    if (value) {
      capStatusBar.hide();
    } else {
      capStatusBar.show();
    }
  }
};
export default toggleStatusBar;
