import type { App } from 'vue';
import VueGtag, { useState, useGtag, type Options } from 'vue-gtag-next';

/* eslint-disable camelcase */
export enum GACategory {
  ORDERBOOK = 'test_exchange_orderbook',
  WITHDRAWAL = 'test_exchange_withdrawal',
  PANDA_MENU = 'test_exchange_panda_menu',
  ORDER = 'test_exchange_order_creation',
  IMMEDIATE_FILL_WARNING = 'test_immediate_fill_warning_{order_type}',
  IMMEDIATE_FILL_WARNING_CLICKED = 'test_immediate_fill_warning_clicked_{order_type}',
  MOVE_MARKET_WARNING = 'test_move_market_warning',
  MOVE_MARKET_WARNING_CLICKED = 'test_move_market_warning_clicked',
}

export enum GAOrderAction {
  INITIATED = 'initiated',
}

export enum GAWithdrawalAction {
  INITIATED = 'initiated',
  COMPLETED = 'completed',
}

export enum GALinkAction {
  CLICKED = 'link_clicked',
}

export enum GAImmediateFillAction {
  WARNING_DISPLAYED = 'warning_displayed',
}

export enum GAImmediateFillClickedAction {
  CANCEL = 'cancel',
  PLACE_ORDER = 'place_order',
  X = 'x',
  OUTSIDE = 'outside',
}

export enum GAMoveMarketAction {
  WARNING_DISPLAYED = 'warning_displayed',
}

export enum GAMoveMarketClickedAction {
  CANCEL = 'cancel',
  PLACE_ORDER = 'place_order',
  X = 'x',
  OUTSIDE = 'outside',
}

type GAAction = GAImmediateFillAction | GAImmediateFillClickedAction | GALinkAction | GAMoveMarketAction | GAMoveMarketClickedAction | GAOrderAction | GAWithdrawalAction;

interface GAEvent {
  eventCategory: GACategory;
  eventLabel: string;
  eventValue?: number;
}
/* eslint-enable camelcase */

type GtagOptions = Options & {
  pageTrackerScreenviewEnabled: boolean;
  config: {
    id: string;
  };
};

class GAService {
  private installed = false;

  public trackGAEvent = (eventCategory: GACategory, eventAction: GAAction, eventLabel: string, eventValue?: number) => {
    if (!this.installed) {
      return;
    }

    const eventData: GAEvent = {
      eventCategory,
      eventLabel,
    };

    if (eventValue) {
      eventData.eventValue = eventValue;
    }

    useGtag().event(eventAction, eventData);
  };

  public init = (app: App) => {
    if (this.installed) {
      return;
    }

    app.use<GtagOptions>(VueGtag, {
      isEnabled: false,
      useDebugger: false,
      appName: 'BitpandaPro',
      pageTrackerScreenviewEnabled: true,
      config: {
        id: process.env.VUE_APP_ANALYTICS_KEY as string,
      },
    });
  };

  public enable = () => {
    const { isEnabled } = useState();

    if (isEnabled !== undefined) {
      isEnabled.value = true;
    }
  };
}

const ga = new GAService();

export default ga;
