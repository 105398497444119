import { computed } from 'vue';

import { marketService } from '@exchange/libs/market/service/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';

class FeaturedCurrencyService {
  private featuredCurrenciesConfiguration = launchdarkly.flags['featured-fiat-currencies'];

  private filterCurrencies = (currencies: string[]) =>
    currencies
      .map((currency) => currency.toUpperCase())
      .filter((currency) => Object.values(marketService.markets.value).some((market) => market?.base.code === currency || market?.quote.code === currency));

  public marketListFeaturedCurrencies = computed(() => this.filterCurrencies(this.featuredCurrenciesConfiguration.value['market-list']));

  public settingsCurrencies = computed(() => this.filterCurrencies(this.featuredCurrenciesConfiguration.value.settings));
}

const featuredCurrencyService = new FeaturedCurrencyService();

export default featuredCurrencyService;
