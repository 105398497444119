/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum PRECISION {
  DEFAULT_CRYPTO = 8,
  DEFAULT_FEE = 4,
  DEFAULT_FIAT = 2,
  DEFAULT_MARKET = 4,
  DEFAULT_PERCENTAGE = 2,
  DEFAULT_UNKNOWN_CURRENCY = 2,
  ZERO = 0,
}
const dateFormat = 'dd/MM/yy, HH:mm:ss';

const HISTORY_DROPDOWN_DISPLAYED_DATE_FORMAT = 'dd/MM/yyyy';
const HISTORY_DROPDOWN_DISPLAYED_DATE_FORMAT_COMPACT = 'dd/MM/yy';
const HISTORY_DROPDOWN_NUMBER_OF_MONTHS_SELECTED = 12;

const bestCode = 'BEST';

const TRADING_LAYOUT_SETTINGS_KEY = 'TRADING_LAYOUT_SETTINGS_KEY';
const FUTURES_LAYOUT_SETTINGS_KEY = 'FUTURES_LAYOUT_SETTINGS_KEY';

const TV_CHART_INTERVAL_KEY = 'TV_CHART_INTERVAL_KEY';

const DEFAULT_FOR_CURRENCY_FORMATTING = 'EUR';

const DEFAULT_MARKET_ID = 'BTC_USDT';

const DEFAULT_MARKET_ID_FUTURES = 'BTC_USDT';

const FEE_LEVEL_CURRENCY = 'BTC';

const DEFAULT_CURRENCIES = {
  fiat: 'EUR',
  crypto: 'BTC',
};
const FIAT_CURRENCIES = ['EUR', 'GBP', 'CHF', 'USD'];

const VISIBLE_TRADES_FOR_ORDER = 6;

const INACTIVE_ORDER_LIST_LENGTH = 100;

const PRICE_TICKS_BUNCH_SIZE = 80;

const SELECTED_MARKET = 'SELECTED_MARKET';

const SELECTED_MARKET_FUTURES = 'SELECTED_MARKET_FUTURES';

const SET_APP_ASLEEP_TIMEOUT = 90_000;

const CLOCK_SKEW_MS = 60_000;

const LS_SUBACCOUNT_ID_KEY = 'SUBACCOUNT_ID';

const MAIN_SUBACCOUNT_NAME = 'Exchange Main';

const MAIN_SUBACCOUNT_NAME_BE = 'main';

const CURRENCY_FILTER_FAVORITES = 'favorites';
const CURRENCY_FILTER_ALL = 'all';

const ROOT_ROUTE_TRANSITION_EVENT_NAME = 'route-transition';

const EXPORT_TRADES_MAX_PAIR_NUMBER = 1000;

const INSTANT_TRADE_ACCOUNT_NAME = 'Instant Trade';

const LOCALE_MAP = {
  /* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat */ en: 'en',
  de: 'de-DE',
};

const AML_PATHNAME = '/onboarding/aml-questionnaire';
const FUTURES_APPROPRIATENESS_ASSESSMENT_PATHNAME = '/futures-appropriateness-assessment';

const CONSTANTS = {
  AML_PATHNAME,
  FUTURES_APPROPRIATENESS_ASSESSMENT_PATHNAME,
  BEST_CODE: bestCode,
  CLOCK_SKEW_MS,
  BALANCE_HIDE_BTC_VALUE: 0.00001,
  BALANCE_HIDE_EUR_VALUE: 1,
  CURRENCY_FILTER_ALL,
  CURRENCY_FILTER_FAVORITES,
  DATE_FORMAT: dateFormat,
  DEFAULT_CURRENCIES,
  DEFAULT_FOR_CURRENCY_FORMATTING,
  DEFAULT_MARKET_ID,
  DEFAULT_MARKET_ID_FUTURES,
  EXPORT_TRADES_MAX_PAIR_NUMBER,
  FEE_LEVEL_CURRENCY,
  FIAT_CURRENCIES,
  HISTORY_DROPDOWN_DISPLAYED_DATE_FORMAT_COMPACT,
  HISTORY_DROPDOWN_DISPLAYED_DATE_FORMAT,
  HISTORY_DROPDOWN_NUMBER_OF_MONTHS_SELECTED,
  INACTIVE_ORDER_LIST_LENGTH,
  INSTANT_TRADE_ACCOUNT_NAME,
  LS_SUBACCOUNT_ID_KEY,
  MAIN_SUBACCOUNT_NAME_BE,
  MAIN_SUBACCOUNT_NAME,
  PRECISION,
  PRICE_TICKS_BUNCH_SIZE,
  ROOT_ROUTE_TRANSITION_EVENT_NAME,
  SELECTED_MARKET,
  SELECTED_MARKET_FUTURES,
  SET_APP_ASLEEP_TIMEOUT,
  TRADING_LAYOUT_SETTINGS_KEY,
  FUTURES_LAYOUT_SETTINGS_KEY,
  TV_CHART_INTERVAL_KEY,
  VISIBLE_TRADES_FOR_ORDER,
  LOCALE_MAP,
};

export default CONSTANTS;
