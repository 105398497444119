import { difference, isEmpty } from 'lodash/fp';
import { computed, reactive, watch } from 'vue';

import BigNumber from '@exchange/helpers/bignumber';
import { isProduction } from '@exchange/helpers/environment';
import getBaseQuote from '@exchange/helpers/get-base-quote';
import { balanceService } from '@exchange/libs/balances/service/src';
import CryptoInfoRest from '@exchange/libs/rest-api/crypto-info-api';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { CurrencyModel, CurrencyType, type CurrencyPrice } from './currency-model';

interface CurrencyState {
  currencies: Dictionary<CurrencyModel>;
  fiatConversions: { [symbol: string]: CurrencyPrice };
  defaultCryptoCurrency: string;
  defaultFiatCurrency: string;
}

class Currency {
  private readonly logPrefix = 'CurrencyService:';

  private getDefaultState = () => ({
    currencies: {},
    fiatConversions: {},
    defaultCryptoCurrency: CONSTANTS.DEFAULT_CURRENCIES.crypto,
    defaultFiatCurrency: CONSTANTS.DEFAULT_CURRENCIES.fiat,
  });

  private readonly state = reactive<CurrencyState>(this.getDefaultState());

  public defaultCryptoCurrency = computed(() => {
    const value = this.state.defaultCryptoCurrency;

    return this.state.currencies[value] ? value : CONSTANTS.DEFAULT_CURRENCIES.crypto;
  });

  public defaultFiatCurrency = computed(() => {
    const value = this.state.defaultFiatCurrency;

    return this.state.currencies[value] ? value : CONSTANTS.DEFAULT_CURRENCIES.fiat;
  });

  public fiatPrecision = computed(() => this.currencies.value?.[this.defaultFiatCurrency.value]?.precision ?? CONSTANTS.PRECISION.DEFAULT_FIAT);

  public currencies = computed(() => (isEmpty(this.state.currencies) ? undefined : (this.state.currencies as Record<string, CurrencyModel>)));

  public waitForCurrencies = (): Promise<Dictionary<CurrencyModel>> => {
    if (!isEmpty(this.state.currencies)) {
      return Promise.resolve(this.state.currencies);
    }

    return new Promise((resolve) => {
      const watchStopHandle = watch(
        () => this.state.currencies,
        // eslint-disable-next-line consistent-return
        (newValue) => {
          if (newValue) {
            watchStopHandle();
            return resolve(newValue);
          }
        },
      );
    });
  };

  public setCurrencies = (currencies: Dictionary<CurrencyModel>) => {
    /* first time */
    if (isEmpty(this.state.currencies)) {
      this.state.currencies = currencies;
      return;
    }

    /* after nap */
    const newNames = Object.keys(currencies);
    const stateNames = Object.keys(this.state.currencies);
    const addedNames = difference(newNames, stateNames);
    const removedNames = difference(stateNames, newNames);

    addedNames.forEach((name) => {
      this.state.currencies[name] = currencies[name];
    });

    removedNames.forEach((name) => {
      delete this.state.currencies[name];
    });
  };

  public fetchCryptoInfo = (symbol: string) => CryptoInfoRest.CoinInfo.get(symbol);

  public fetchPrices = () => CryptoInfoRest.Prices.get();

  public fetchFiatConversions = async () => {
    try {
      const conversions = (await CryptoInfoRest.Conversions.get()).reduce((acc, conversion) => {
        const { base } = getBaseQuote(conversion.instrument_code, '_');

        acc[base] = { eur: conversion.value, symbol: base };

        return acc;
      }, {});

      this.state.fiatConversions = {
        ...conversions,
        EUR: { symbol: 'EUR', eur: '1' } as CurrencyPrice,
      };
    } catch (error) {
      logger.warn(`${this.logPrefix} Fiat conversions fetching failed; retrying later`, error);
      await retryService.waitForNextRetryTick();
      await this.fetchFiatConversions();
    }
  };

  public pollPrices = {
    timeoutId: 0,
    timeoutMs: 60_000,
    start: async () => {
      if (this.pollPrices.timeoutId) {
        this.pollPrices.stop();
      }

      await this.handleCurrenciesPrices();
      this.pollPrices.timeoutId = window.setTimeout(this.pollPrices.start, this.pollPrices.timeoutMs);
    },
    stop: () => {},
  };

  private updateCurrenciesDefaultCryptoFiatValues = (prices: { [key: string]: CurrencyPrice }) => {
    Object.keys(this.state.currencies).forEach((currencyCode: string) => {
      const cur = this.state.currencies[currencyCode];
      const price = prices[currencyCode];

      if (cur && price) {
        const cryptoValue = price[this.defaultCryptoCurrency.value.toLocaleLowerCase()];
        const fiatValue = price[this.defaultFiatCurrency.value.toLocaleLowerCase()];

        if (cryptoValue !== undefined) {
          cur.defaultCryptoValue = cryptoValue;
        } else {
          // there are no prices for fiat (fiat in crypto), UI needs to try invert value crypto in fiat
          const cryptoPrice = prices[this.defaultCryptoCurrency.value];

          if (cryptoPrice) {
            const fiatPrice = cryptoPrice[cur.symbol.toLocaleLowerCase()];

            if (fiatPrice !== undefined && Number(fiatPrice) !== 0) {
              cur.defaultCryptoValue = new BigNumber(1).div(fiatPrice).toNumber();
            }
          }
        }

        if (fiatValue !== undefined) {
          cur.defaultFiatValue = fiatValue;
        }
      } else if (cur && isProduction) {
        logger.warn(`${this.logPrefix} Prices are missing for ${cur?.symbol}`);
      }
    });
  };

  private handleCurrenciesPrices = async () => {
    const cryptoPrices = await this.fetchPrices().catch(() => [] as Array<CurrencyPrice>);

    if (!cryptoPrices.length) {
      return;
    }

    const cryptoPricesObj = cryptoPrices.reduce(
      (acc, price) => {
        acc[price.symbol] = price;
        return acc;
      },
      {} as { [key: string]: CurrencyPrice },
    );
    const prices = {
      ...this.state.fiatConversions,
      ...cryptoPricesObj,
    };

    this.updateCurrenciesDefaultCryptoFiatValues(prices);
  };

  public fetchCurrencies = async () => {
    try {
      const beProData = await PublicRest.Currencies.get();
      const proCurrencies = beProData.reduce((acc, item) => {
        acc[item.code] = new CurrencyModel({
          id: item.unified_cryptoasset_id,
          name: item.name,
          precision: item.precision,
          symbol: item.code,
          defaultCryptoValue: 0,
          defaultFiatValue: 0,
        });

        return acc;
      }, {});

      balanceService.addBalanceCurrencies(proCurrencies);
      this.setCurrencies(proCurrencies);
      await this.fetchFiatConversions();
      this.pollPrices.start();
    } catch (error) {
      logger.warn('Fetching currencies failed; retrying later', error);
      await retryService.waitForNextRetryTick();
      await this.fetchCurrencies();
    }
  };

  public updateDefaultCurrencies = (update: { [CurrencyType.FIAT]: string }) => {
    const updatedFiatCurrency = update[CurrencyType.FIAT];

    if (updatedFiatCurrency) {
      const previous = this.state.defaultFiatCurrency;

      if (previous !== updatedFiatCurrency) {
        this.state.defaultFiatCurrency = updatedFiatCurrency;
        this.handleCurrenciesPrices();
      }
    }
  };

  public getCurrencyPrecision = (currencyCode: string) => this.currencies.value?.[currencyCode]?.precision;

  public getCurrencyFeePrecision = (currencyCode: string) => this.currencies.value?.[currencyCode]?.precision || CONSTANTS.PRECISION.DEFAULT_FEE;

  public getCurrencyName = (currencyCode: string) => this.currencies.value?.[currencyCode]?.getCurrencyName();
}

const currencyService = new Currency();

export default currencyService;
