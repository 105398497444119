import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

import { logger } from '@exchange/libs/utils/simple-logger/src';

class IOSTrackerTransparency {
  public validate = async (statusToValidate?: string): Promise<boolean> => {
    let currentStatus = statusToValidate;

    if (!currentStatus) {
      const { status } = await this.getStatus();

      currentStatus = status;
    }

    /**
     * https://developer.apple.com/documentation/apptrackingtransparency/attrackingmanager/authorizationstatus
     */
    switch (currentStatus) {
      case 'authorized':
        return true;
      case 'denied':
        return false;

      case 'notDetermined': {
        const response = await this.requestPermission();

        return this.validate(response.status);
      }

      case 'restricted':
        return false;

      default:
        logger.error('Unknown transparency status:', statusToValidate);
        return false;
    }
  };

  private getStatus = async () => {
    const response = await AppTrackingTransparency.getStatus();

    return response;
  };

  private requestPermission = async () => {
    const response = await AppTrackingTransparency.requestPermission();

    return response;
  };
}

export default new IOSTrackerTransparency();
