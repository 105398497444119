import { capIsNativePlatform } from './capacitor';

const capCookieWorkaround = (isNativePlatform: boolean) => {
  if (!isNativePlatform) {
    return;
  }

  const cookieDesc = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie');

  const proCookiePrefix = 'pro-cookie-';

  if (cookieDesc && cookieDesc.configurable) {
    Object.defineProperty(document, 'cookie', {
      get() {
        const res: Array<string> = [];

        Object.keys(localStorage).forEach((key) => {
          if (key.startsWith(proCookiePrefix)) {
            const item = JSON.parse(localStorage.getItem(key) || '{}');

            if (item.expires && new Date(item.expires) < new Date()) {
              localStorage.removeItem(key);
            } else {
              res.push(`${key.replace(proCookiePrefix, '')}=${item.value}`);
            }
          }
        });
        return res.join('; ');
      },
      set(val) {
        cookieDesc.set?.call(document, val);

        const cInfo = val.split(';').map((e) => e?.trim());
        const nameData = cInfo[0];

        if (!nameData) {
          return;
        }

        const cSplit = (s: string) => s.split(/=(.*)/s);

        const [name, value] = cSplit(nameData).map((e) => e.trim());

        if (name === undefined || value === undefined) {
          return;
        }

        const cInfoObject = cInfo.reduce((acc, e) => {
          const [k, v] = cSplit(e.trim());

          if (k !== undefined) {
            acc[k] = v;
          }

          return acc;
        }, {});

        localStorage.setItem(
          `${proCookiePrefix}${name}`,
          JSON.stringify({
            ...cInfoObject,
            value,
          }),
        );
      },
    });
  }
};

export default capCookieWorkaround(capIsNativePlatform());
