import { logger } from '@exchange/libs/utils/simple-logger/src';

import { capBrowser } from './capacitor';

const handleNativeGoTo = async (url: string, onBrowserFinished?: () => void, onError?: (e: unknown) => void) => {
  try {
    await capBrowser.addListener('browserFinished', () => {
      capBrowser.removeAllListeners();
      onBrowserFinished?.();
    });
    await capBrowser.open({ url });
  } catch (e) {
    logger.error(`Go to ${url} failed`, e);
    onError?.(e);
  }
};

export default handleNativeGoTo;
