import { computed, reactive, watch } from 'vue';

import { gaService } from '@exchange/libs/utils/google-analytics/src';
import { iosTrackerService } from '@exchange/libs/utils/ios-tracker-transparency/src';
import { zendeskService } from '@exchange/libs/utils/zendesk/src';

import CookieBannerManager from './cookie-banner';

export interface CookieSettings {
  advertisementAccepted: boolean;
  performanceAccepted: boolean;
  essentialAccepted: boolean;
}

type BPCCookieBannerId = 'uc_essentials' | 'uc_performance' | 'uc_advertisement';

class CookieService {
  public readonly cookieBanner = {
    manager: new CookieBannerManager(),
    onAcceptCookies: (cookies: { detail: Array<{ id: BPCCookieBannerId; accepted: boolean }> }) => {
      const cookieSettings = cookies.detail.reduce((acc, cookie) => {
        switch (cookie.id) {
          case 'uc_essentials':
            acc.essentialAccepted = cookie.accepted;
            break;
          case 'uc_performance':
            acc.performanceAccepted = cookie.accepted;
            break;
          case 'uc_advertisement':
            acc.advertisementAccepted = cookie.accepted;
            break;
          default:
            break;
        }

        return acc;
      }, {} as CookieSettings);

      this.setCookieSettings(cookieSettings);
    },
  };

  private readonly cookieSettings = reactive<CookieSettings>({
    advertisementAccepted: false,
    performanceAccepted: false,
    essentialAccepted: false,
  });

  public readonly cookiePerformanceAccepted = computed(() => this.cookieSettings.performanceAccepted);

  private readonly setCookieSettings = ({ advertisementAccepted, performanceAccepted, essentialAccepted }: CookieSettings) => {
    this.cookieSettings.advertisementAccepted = advertisementAccepted;
    this.cookieSettings.performanceAccepted = performanceAccepted;
    this.cookieSettings.essentialAccepted = essentialAccepted;
  };

  constructor() {
    watch(
      this.cookiePerformanceAccepted,
      async (accepted) => {
        if (accepted) {
          gaService.enable();
          zendeskService.updateCookies(true);
        }
      },
      { immediate: true },
    );
  }

  private handleIOSTracking = () =>
    iosTrackerService.validate().then((response: boolean) => {
      this.setCookieSettings({
        advertisementAccepted: response,
        essentialAccepted: response,
        performanceAccepted: response,
      });
    });

  private handleAndroidTracking = () =>
    this.setCookieSettings({
      advertisementAccepted: true,
      essentialAccepted: true,
      performanceAccepted: true,
    });

  public handleNativeTracking = (platform: string) => {
    switch (platform) {
      case 'android':
        return this.handleAndroidTracking();
      case 'ios':
        return this.handleIOSTracking();
      default:
        return undefined;
    }
  };
}

const cs = new CookieService();

export default cs;
